import {Container, TextField, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {Alert} from "@material-ui/lab";
import {LoadingButton} from "../LoadingButton";
import {Api} from "../api";
import {useDispatch} from "react-redux";
import {GameData, initializeAction} from "../redux";

export function Welcome() {
    const [joinCode, setJoinCode] = useState('');
    const [isJoining, setJoining] = useState(false);
    const [error, setError] = useState('');
    const dispatch = useDispatch();

    const join = async () => {
        if (joinCode.trim() === '') {
            return setError('Please enter a join code.');
        }

        if (!joinCode.includes('.')) {
            return setError('Sorry this join code doesn\'t seem to be correct. Please check if you included both parts before and after the \'.\' (dot) character.');
        }

        setJoining(true);
        const gameId = parseInt(joinCode.split('.')[0]);
        try {
            Api.setAuth(joinCode);
            const gameInstance = await Api.getGameInfo(gameId);
            // console.log("Game instance:", gameInstance);
            // console.log("Game type:", gameInstance.game_type);
            if (gameInstance.finished_at != null) {
                return setError('This game is already finished! Please check that you have correct code.');
            }
            const assetFilename = `${gameInstance.game_type}.json`;
            // console.log("Asset filename:", assetFilename);
            const gameData: GameData = await Api.getAsset(assetFilename, "json");
            // console.log("Game data:", gameData);
            dispatch(initializeAction(gameInstance, gameData));
        } catch (e) {
            // console.error("Join error:", e);
            setError('This join code was rejected. Try clicking on the link in the email directly.');
            setJoining(false);
        }
    };

    const formSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        join();
        e.preventDefault();
    };

    return <Container>
        <Typography variant={"h4"} style={{marginTop: '1em'}} gutterBottom>Welcome to Mystery Dinner
            application.</Typography>
        <Typography>You should have received a code in a email from your game
            master. The easiest way to join your game is to click the link in the email. If you haven't received an
            email with join link or join code please contact your game master.</Typography>

        <Typography style={{marginTop: '2em'}} variant={"h5"}>I have join code!</Typography>
        <Typography>You can also enter the web join code manually into the following text field and join
            your game.</Typography>
        <form style={{margin: '1em 0', display: 'flex', flexDirection: 'column'}} onSubmit={(e) => formSubmit(e)} noValidate autoComplete={"off"}>
            <TextField label="Web Join Code" variant="outlined" disabled={isJoining} value={joinCode}
                       onChange={(e) => setJoinCode(e.target.value)} fullWidth/>
            <LoadingButton loading={isJoining} onClick={() => join()} variant="contained" color="primary"
                           style={{margin: '1em 0 0 auto'}}>
                Join Game
            </LoadingButton>
        </form>
        {error && <Alert severity={"error"}>{error}</Alert>}
    </Container>;
}
