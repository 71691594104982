import {AxiosInstance, ResponseType} from "axios";
import {CharacterId, GameInstance, WireGameEvent} from "./redux";

const axios = require('axios').default;

const STORAGE_TOKEN_KEY = '_token';
const API_BASE_URL = 'https://mysterydinner.brainteaselava.sk/';

export class ApiInner {
    private axios: AxiosInstance;

    constructor() {
        this.axios = axios.create({
            baseURL: API_BASE_URL,
            headers: {
                'X-Web-Token': localStorage.getItem(STORAGE_TOKEN_KEY),
                // 'X-App-Token': localStorage.getItem(STORAGE_TOKEN_KEY),
            }
        });
    }

    setAuth(joinCode: string) {
        localStorage.setItem(STORAGE_TOKEN_KEY, joinCode);
        this.axios = axios.create({
            baseURL: API_BASE_URL,
            headers: {
                'X-Web-Token': localStorage.getItem(STORAGE_TOKEN_KEY),
                // 'X-App-Token': localStorage.getItem(STORAGE_TOKEN_KEY),
            }
        });
    }

    async getGameInfo(gameId: number): Promise<GameInstance> {
        return (await this.axios.get(`/games/${gameId}`)).data;
    }

    async getGameEvents(gameId: number, fromId: number): Promise<WireGameEvent[]> {
        return (await this.axios.get(`/games/${gameId}/events`, {
            params: {
                from_id: fromId,
            }
        })).data;
    }

    async selectCharacter(gameId: number, selectedCharacter: CharacterId) {
        await this.axios.post(`/games/${gameId}/character`, {
            id: selectedCharacter
        });
    }

    async getAsset<T>(relativePath: string, responseType: ResponseType = 'blob'): Promise<T> {
        // console.log("getAsset called with relativePath:", relativePath);
        return (await this.axios.get<T>(`/assets`, {
            responseType,
            params: {
                'path': relativePath
            }
        })).data;
    }
}

export const Api = new ApiInner();
